// React Imports
import React, { Fragment, useContext, useEffect } from 'react';

// React - Bootstrap Imports
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//  CSS Import
import './cardModule.css';

const placeholderText =
    'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cum atque optio voluptatem ab dolorem sed aut inventore repudiandae totam? Ab consequatur sed repellendus deleniti placeat illum ducimus laudantium fugit id.';

// Content import
function CardModule(props) {
    const title = props.title ?? 'title prop undefined';
    const text = props.text ?? 'text prop undefined';
    const left = props.left ?? false;
    const right = props.right ?? false;
    const header = props.header ?? false;
    const edgeColour = props.edgeColour ?? '#FFFF00';
    const backgroundColour = 'rgba(255,255,255,0.1)'; // hsl(229, 6%, 66%)
    const boxShadowColour = 'rgba(255,255,255,0.4)';

    const cardSytle = {
        boxShadow: `0px 1rem 1.5rem -1.1rem ${header ? edgeColour : boxShadowColour}`,
        backgroundColor: backgroundColour,

        padding: header ? '0.5rem 1.5rem' : '0.5rem 1rem',

        marginTop: 'none',
        marginBottom: header ? '1.5rem' : '1rem',
        // marginleft: left ? '5rem' : 'none',
        // marginRight: right ? '5rem' : 'none',

        borderTop:
            !left && !right && !header
                ? `1px solid ${edgeColour}`
                : header
                ? 'none'
                : `1px solid ${boxShadowColour}`,
        borderBottom: header ? `2px solid ${edgeColour}` : `1px solid ${boxShadowColour}`,
        borderLeft: left
            ? `1px solid ${edgeColour}`
            : header
            ? 'none'
            : `1px solid ${boxShadowColour}`,
        borderRight: right
            ? `2px solid ${edgeColour}`
            : header
            ? 'none'
            : `1px solid ${boxShadowColour}`,
        borderRadius: '0.5rem',

        color: 'lightgrey',
    };

    function headerCard() {
        return (
            <Fragment>
                <Col xs='auto'>
                    <div className='cardModule header' style={cardSytle}>
                        <div className='content'>
                            <h3>{title}</h3>
                        </div>
                    </div>
                </Col>
            </Fragment>
        );
    }

    function leftCard() {
        return (
            <Fragment>
                <Col xs='auto'>
                    <div className='cardModule left' style={cardSytle}>
                        <div className='content'>
                            <h5>{title}</h5>
                            <p>{text}</p>
                        </div>
                    </div>
                </Col>
                <Col xs='2' />
            </Fragment>
        );
    }

    function middleCard() {
        return (
            <Fragment>
                <Col xs='auto'>
                    <div className='cardModule middle' style={cardSytle}>
                        <div className='content'>
                            <h5>{title}</h5>
                            <p>{text}</p>
                        </div>
                    </div>
                </Col>
            </Fragment>
        );
    }

    function rightCard() {
        return (
            <Fragment>
                <Col xs='2' />
                <Col xs='auto'>
                    <div className='cardModule right' style={cardSytle}>
                        <div className='content'>
                            <h5>{title}</h5>
                            <p>{text}</p>
                        </div>
                    </div>
                </Col>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <Row className={'justify-content-center'}>
                {left ? leftCard() : right ? rightCard() : header ? headerCard() : middleCard()}
            </Row>
        </Fragment>
    );
}

export default CardModule;
