// React Imports
import React, { Fragment } from 'react';

//  CSS Import
import './style.css';

function AnchorPoint(props) {
    const hide = props.hide ?? false;
    const anchorName = props.name ?? null;

    const style = {
        height: hide ? '0px' : '56px',
        width: hide ? '0px' : '100%',
        visibility: hide ? 'hidden' : 'visible',
    };

    return (
        <Fragment>
            <div className='anchorPoint' id={anchorName} style={style} />
        </Fragment>
    );
}

export default AnchorPoint;
