// React Imports
import React, { Fragment } from 'react';

// React - Bootstrap Imports
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';

//  CSS Import
import './style.css';

// Content import
import navLogo from './svg/navBarLogo.svg';

function NavBar() {
    return (
        <Fragment>
            <Navbar
                collapseOnSelect
                bg='dark'
                variant='dark'
                expand='md'
                className='navBar'
                id='navBar'
                sticky='top'>
                <Container>
                    <Navbar.Brand href='/#heroAnchor'>
                        <img src={navLogo} width='45' height='30' className='d-inline-block' />{' '}
                        ModernKiwi Ltd
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                    <Navbar.Collapse id='responsive-navbar-nav'>
                        <Nav className='me-auto'>
                            <Nav.Link href='/#aboutCompanyAnchor'>About MK</Nav.Link>
                            <Nav.Link href='/#aboutMeAnchor'>About Me</Nav.Link>
                            <Nav.Link href='/#servicesAnchor'>Services</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Fragment>
    );
}

export default NavBar;
