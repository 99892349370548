//  Base Imports
import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';

//  State Imports
import AuthState from './context/auth/AuthState';
import AlertState from './context/alert/AlertState';

//  Page Imports
import Home from './pages/Home';

function App() {
    return (
        <AuthState>
            <AlertState>
                <Router>
                    <div>
                        <Home />
                    </div>
                </Router>
            </AlertState>
        </AuthState>
    );
}

export default App;
