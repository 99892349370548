// React Imports
import React, { Fragment, useContext, useEffect } from 'react';

// React - Bootstrap Imports
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';

//  CSS Import
import './aboutMe.css';

// Content import

function AboutMe() {
    return (
        <Fragment>
            <div className='aboutMe' id='aboutMe'>
                <section className='container'>
                    <h1>About Me (Place Holder)</h1>
                    <p>
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quas atque ducimus
                        esse explicabo voluptatibus harum neque asperiores ipsa, dolore vitae
                        architecto? Est sint rem sunt rerum. Praesentium incidunt qui quidem!
                        Tempora error dolor, facilis reprehenderit iure enim quae ipsum voluptas et
                        suscipit quam minus dicta facere perferendis architecto fugiat? Tempora
                        quibusdam ut aut ab explicabo harum quam architecto odit temporibus!
                        Doloremque doloribus suscipit eligendi facilis modi tempore deleniti
                        tempora, quo repellat quibusdam odit provident blanditiis accusamus. Ipsum
                        non nam quasi delectus laboriosam? Repellendus rerum earum quos? Ducimus
                        obcaecati enim nesciunt? Labore inventore explicabo modi, est obcaecati
                        perferendis in sunt quos alias voluptatem dolore eligendi veniam similique
                        quae. Natus labore, praesentium doloribus necessitatibus dolor sunt
                        explicabo iusto id quis odio voluptatem. Quam voluptas fugit facere sequi
                        sunt consequatur a rem dolorem deleniti ipsum laudantium harum qui, quo in
                        sed quis numquam corporis est quisquam amet perspiciatis eaque sint? Maxime,
                        repellat fuga.
                    </p>
                </section>
            </div>
        </Fragment>
    );
}

export default AboutMe;
