//  React imports
import React, { Fragment } from 'react';

//  Layout Imports
import Hero from '../components/layout/Hero/Hero';
import NavBar from '../components/layout/NavBar/NavBar';
import AnchorPoint from '../components/layout/AnchorPoint/AnchorPoint';
import AboutCompany from '../components/layout/AboutCompany/AboutCompany';
import AboutMe from '../components/layout/AboutMe/AboutMe';
import CompanyServices from '../components/layout/CompanyServices/CompanyServices';

const Home = () => {
    return (
        <Fragment>
            <Hero fullscreen showNavBar />
            <AnchorPoint hide name='aboutCompanyAnchor' />
            <NavBar />
            <AboutCompany />
            <AnchorPoint name='aboutMeAnchor' />
            <AboutMe />
            <AnchorPoint name='servicesAnchor' />
            <CompanyServices />
        </Fragment>
    );
};

export default Home;
