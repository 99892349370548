// React Imports
import React, { Fragment, useContext, useEffect } from 'react';

// React - Bootstrap Imports
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';

//  CSS Import
import './aboutCompany.css';

//  Content import

//  Module import
import CardModule from './modules/CardModule';

function AboutCompany() {

    return (
        <Fragment>
            <div className='aboutCompany' id='aboutCompany'>
                <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Row xs='1' className='justify-content-center'>
                        <Col>
                            <div className='spacerTop' />
                        </Col>
                        <Col>
                            <section className='container'>
                                <Container fluid>
                                    <CardModule
                                        header
                                        title='ModernKiwi Origin'
                                        edgeColour='#00DEFF'
                                    />
                                    <CardModule
                                        left
                                        title='May 2018:'
                                        text='I was looking to have my own personal email domain and Modern.Kiwi was the result'
                                        edgeColour='red'
                                    />
                                    <CardModule
                                        right
                                        title='May 2019:'
                                        text='Started making content on Twitch and Youtube under the name ModernKiwi'
                                        edgeColour='blue'
                                    />
                                    <CardModule
                                        title='New Value aquired:'
                                        text='After helping a viewer I decided to incorperate mental health awareness'
                                        edgeColour='gold'
                                    />
                                    <CardModule
                                        left
                                        title='Oct 2019:'
                                        text='participated in Movember raising $1310 towards Mens mental health and suicide prevention'
                                        edgeColour='purple'
                                    />
                                    <CardModule
                                        right
                                        title='Jun 2020:'
                                        text='Officially made ModernKiwi a company'
                                        edgeColour='lightgreen'
                                    />
                                    <CardModule
                                        left
                                        title='Jun 2020:'
                                        text='Officially made ModernKiwi a company'
                                        edgeColour='lightgreen'
                                    />
                                    <CardModule
                                        left
                                        title='Feb 2021:'
                                        text='Continued to persue my interest in Web Dev, implimented into ModernKiwi'
                                        edgeColour='red'
                                    />
                                    <CardModule
                                        right
                                        title='Aug 2021:'
                                        text='Started working on the new Modern.Kiwi site'
                                        edgeColour='purple'
                                    />
                                    <CardModule
                                        title='Goals:'
                                        text='Continue to grow ModernKiwi as a web hosting service, and to build an amazing community'
                                    />
                                </Container>
                            </section>
                        </Col>
                        <Col>
                            <div className='spacerBottom' />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default AboutCompany;
